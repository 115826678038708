import { useApi, useAuth } from '../../../hooks';
import { useGoogleTagManager } from '../../../hooks/useGoogleTagManager';
import { useSegment } from '../../../hooks/useSegment';
import { LOCAL_STORAGE_USER_INFO_KEY } from '../../../constants/misc';
import {
  TRACKING_INITIATE_CHECKOUT,
  TRACKING_MEMBERSHIP_PAGE,
  TRACKING_PLANS_SCREEN,
  TRACKING_STRIPE_PAYMENT_PAGE,
} from '../../OnboardingForm/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ApiRequest } from '../../../types';
import { useAffiliate } from '../../../api/affiliates/useAffiliate';

const { REACT_APP_WEB_BASE_URL = 'http://localhost:3000' } = process.env;

const DEFAULT_DISCOUNT = 15;
const DEFAULT_DISCOUNT_CODE = 'SAVE15';

const areStripeUserInfoValid = (userId, userEmail) => {
  if (!userId || !userEmail) return false;

  const localStorageUserInfo = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_USER_INFO_KEY) || '{}'
  );
  const {
    userEmail: localStorageUserEmail = null,
    userId: localStorageUserId = null,
  } = localStorageUserInfo;

  return userId === localStorageUserId && userEmail === localStorageUserEmail;
};

const computeUserInformation = (
  locationState,
  authenticatedUser,
  searchParams
) => {
  const userId = locationState?.userId || authenticatedUser?.id;
  const userEmail = locationState?.userEmail || authenticatedUser?.email;

  if (userId && userEmail) return { userId, userEmail, isFromStripe: false };

  const userIdQueryParam = decodeURIComponent(searchParams.get('userId'));
  const userEmailQueryParam = decodeURIComponent(searchParams.get('userEmail'));

  return {
    userId: userIdQueryParam,
    userEmail: userEmailQueryParam,
    isFromStripe: userIdQueryParam && userEmailQueryParam,
  };
};

// For users that previously completed the onboarding flow but didn't finish the Stripe flow,
// they land on this page after logging in and confirming their registration data on the unsubscribedLandingPage.
export const usePlansPage = () => {
  const { api } = useApi();
  const { user: authenticatedUser, loading } = useAuth();
  const { search, state } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { userId, userEmail, isFromStripe } = computeUserInformation(
    state,
    authenticatedUser,
    searchParams
  );

  const { trackEventInGoogleTagManager } = useGoogleTagManager();
  const { page: pageView } = useSegment();
  const navigate = useNavigate();
  const comesFromOnboarding = !!state?.userId;
  const noUser = !state?.userId && !authenticatedUser?.id;

  const { affiliate, isInitialLoading: isLoadingAffiliate } = useAffiliate();
  const { discount, discount_code } = affiliate || {};

  useEffect(() => {
    if (!comesFromOnboarding) return;

    localStorage.setItem(
      LOCAL_STORAGE_USER_INFO_KEY,
      JSON.stringify({
        userEmail,
        userId,
      })
    );
    pageView(TRACKING_MEMBERSHIP_PAGE);
    trackEventInGoogleTagManager(TRACKING_PLANS_SCREEN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectHome = (toRegister = false) => {
    navigate(`/${search}`, {
      state: { toRegister },
    });
  };

  const handleStripeCheckout = async (checkoutData: object) => {
    try {
      const checkoutRequest: ApiRequest<string> = {
        route: '/private/stripe/checkout',
        method: 'POST',
        payload: JSON.stringify(checkoutData),
      };

      const checkoutResponse = await api(checkoutRequest);

      return checkoutResponse;
    } catch (e: any) {
      console.error('api error', e?.message);
    }
  };

  const redirectToStripe = (responseUrl) => {
    pageView(TRACKING_STRIPE_PAYMENT_PAGE);
    trackEventInGoogleTagManager(TRACKING_INITIATE_CHECKOUT);

    // give some time to run the tracking functions before changing page
    setTimeout(() => {
      window.location.replace(responseUrl);
    }, 1000);
  };

  const handlePlanSelect = async (price) => {
    if (!price) return;

    const { id: priceId, recurring } = price;

    // if price has a trial period, include it in the payload
    const freeTrialPayload = recurring?.trial_period_days
      ? {
          subscription_data: {
            trial_period_days: recurring.trial_period_days,
            trial_settings: {
              end_behavior: {
                missing_payment_method: 'cancel',
              },
            },
          },
        }
      : {};

    const checkoutData = {
      mode: 'subscription',
      customer_email: userEmail,
      client_reference_id: userId,
      allow_promotion_codes: true,
      line_items: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      // this param tells stripe not to collect payment if not required
      // payment_method_collection: 'if_required',
      success_url: `${REACT_APP_WEB_BASE_URL}/thank-you?userId=${encodeURIComponent(
        userId
      )}`,
      cancel_url: `${REACT_APP_WEB_BASE_URL}/plans?userId=${encodeURIComponent(
        userId
      )}&userEmail=${encodeURIComponent(userEmail)}`,
      ...freeTrialPayload,
    };

    const response = await handleStripeCheckout(checkoutData);
    const { url: responseUrl } = response;

    if (responseUrl) {
      redirectToStripe(responseUrl);
    } else {
      console.error('Missing response/url from checkout', response);
    }
  };

  if (noUser && !loading) {
    if (!isFromStripe || !areStripeUserInfoValid(userId, userEmail)) {
      redirectHome(false);
    }

    // do nothing: the users will go in the plans page
    // once back from Stripe checkout
  }

  return {
    discount: discount ?? DEFAULT_DISCOUNT,
    discount_code: discount_code ?? DEFAULT_DISCOUNT_CODE,
    handlePlanSelect,
    isLoadingAffiliate,
  };
};
