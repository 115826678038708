import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useApi } from '../../hooks/useApi';
import { StripePrice, StripeProduct } from '../../types';

/**
 * Assumption is that only one Stripe product will map to each config category (digital or coach)
 *
 * Stripe product is mapped to config category through the healthcoach (productConfig.digital.healthCoach === product.metadata.healthcoach)
 */
const productConfig = {
  digital: {
    toggleButtonText: 'Digital Only',
  },
  coach: {
    healthCoach: 'True',
    toggleButtonText: 'With Coach',
  },
};

export type ProductCategory = keyof typeof productConfig;

interface IPlan {
  heading: string;
  toggleButtonText: string;
  product?: StripeProduct;
  prices?: StripePrice[];
}

type PlanConfig = {
  [category in ProductCategory]?: IPlan;
};

export const useStripePlans = () => {
  const { api } = useApi();

  const {
    data: { data: products } = {},
    isLoading: productsLoading,
    isError: productsError,
  } = useQuery<{ data: StripeProduct[] }, Error>(
    ['stripe-products'],
    () => getStripeProducts(),
    {
      staleTime: Infinity,
    }
  );

  const {
    data: { data: unfilteredPrices } = {},
    isLoading: pricesLoading,
    isError: pricesError,
  } = useQuery<{ data: StripePrice[] }>(
    ['stripe-prices'],
    () => getStripePrices(),
    {
      staleTime: Infinity,
    }
  );

  // exclude prices without metadata.app === 'enabled'
  // TODO: move this filter to the backend
  const prices = useMemo(() => {
    return unfilteredPrices?.filter(
      (price: StripePrice) => price.metadata.app === 'enabled'
    );
  }, [unfilteredPrices]);

  const getStripeProducts = async () => {
    return await api({
      route: '/stripe/products?show=enabled',
    });
  };

  const getStripePrices = async () => {
    return await api({
      route: '/stripe/prices?limit=50',
    });
  };

  /**
   * Combine Stripe products and prices with a productConfig category
   *
   * Returns digital + coach product config with product and prices for each
   */
  const planConfig: PlanConfig = useMemo(() => {
    if (!products || !prices) return {};
    // reverse order so Coach plan shows first
    products.reverse();

    return products.reduce((config, product) => {
      // find product's productConfig category using healthcoach values
      const category = Object.keys(productConfig).find(
        (category) =>
          productConfig[category].healthCoach === product.metadata.healthcoach
      );

      if (!category) return config;

      // find prices to be associated with category
      const productPrices = prices
        .filter((price) => price.product === product.id)
        // order prices by interval_count
        .sort((a, b) =>
          a.recurring && b.recurring
            ? a.recurring?.interval_count - b.recurring?.interval_count
            : 0
        );

      return {
        ...config,
        [category]: {
          ...productConfig[category],
          product: product,
          prices: productPrices,
        },
      };
    }, {});
  }, [products, prices]);

  return {
    error: pricesError || productsError,
    planConfig,
    loading: pricesLoading || productsLoading,
  };
};

export default useStripePlans;
