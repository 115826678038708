import styled from '@emotion/styled';
import {
  Box,
  Button,
  CloseIcon,
  Flex,
  FormControl,
  Heading,
  HStack,
  Image,
  Input,
  ITheme,
  Link,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import { useRef } from 'react';
import { useLogin } from '../../hooks/useLogin';
import { ReviewCard } from '../ui/ReviewCard';
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams';

const highFiveImg = '../../../celebrate-phase3-completion.png';

const GradientBox = styled('div')<{ theme?: ITheme }>`
  background-image: linear-gradient(to bottom, #ebf5ff, #ffffff);
`;

export const REVIEWS = [
  {
    content: (
      <Text fontWeight={'normal'} fontSize={'md'}>
        “My journey with GI health over the past 20 years has been really
        challenging and lonely.
        <br />
        <br />
        <b>
          I felt so heard and supported after my first meeting with my Ayble
          care team.
        </b>
        I actually look forward to our appointments! Awesome, absolutely
        awesome.”
      </Text>
    ),
    name: 'Sam R., IBD',
    stars: 5,
  },
  {
    content: (
      <Text fontWeight={'normal'} fontSize={'md'}>
        “My original goal was{' '}
        <b>to have a normal BM every day and I got there thanks to Ayble</b> —
        it’s like clockwork now! <br />
        <br /> After many challenging and lonely years,
        <b>I felt so heard and supported by my Ayble care team.</b> They were
        awesome!”
      </Text>
    ),
    name: 'Carol Z., Constipation',
    stars: 5,
  },
];

type Props = {
  variant?: 'regular' | 'small';
};

export const LoginPanel = ({ variant = 'regular' }: Props) => {
  const navigate = useNavigateWithParams();
  const loginEmailRef = useRef<HTMLInputElement>();
  const { isLoading, loginMessage, sendNewLoginLink } = useLogin();

  return (
    <VStack position="sticky" top={0} zIndex={11}>
      <GradientBox>
        <Flex
          alignItems="start"
          flexDirection="row"
          justifyContent="space-between"
          p={6}
        >
          <HStack alignItems="center">
            <Pressable variant={'closer'} onPress={() => navigate('/')}>
              <CloseIcon color="secondary.200" />
            </Pressable>
          </HStack>
        </Flex>

        <VStack
          alignItems="center"
          p={24}
          pt={variant === 'small' ? 3 : undefined}
        >
          <Box>
            <Heading color={'secondary.500'} fontWeight={'normal'}>
              Welcome back!
            </Heading>

            <VStack alignItems="center" pt={6}>
              <Text
                color={'secondary.200'}
                fontWeight={'normal'}
                maxW={'270px'}
                textAlign={'center'}
              >
                To sign in, enter your email and we’ll send you a login link to
                confirm your identity. This is the safest way to protect your
                data!
              </Text>
            </VStack>

            <VStack
              alignItems="center"
              justifyContent={'center'}
              minH={'17px'}
              mt="5"
            >
              {!!loginMessage && (
                <Text color={'secondary.200'} fontWeight={'bold'}>
                  {loginMessage}
                </Text>
              )}
            </VStack>

            <FormControl mt="5">
              <Input
                ref={loginEmailRef}
                rounded={'3xl'}
                type="text"
                placeholder="Email"
                w={'340px'}
                maxW={'100%'}
              />
            </FormControl>

            <Button
              _hover={{
                bgColor: 'secondary.400',
              }}
              bgColor={'secondary.500'}
              disabled={isLoading}
              isLoading={isLoading}
              mt={3}
              onPress={() => {
                if (!loginEmailRef?.current?.value) return;

                sendNewLoginLink(loginEmailRef.current.value);
              }}
            >
              <Text
                color={'white'}
                fontSize={'md'}
                fontWeight={'medium'}
                textTransform={'none'}
              >
                Send Login Link
              </Text>
            </Button>

            <VStack alignItems="center" pt={4}>
              <Text color={'secondary.500'} fontWeight={'normal'}>
                First time here?{' '}
                <Link
                  _hover={{
                    _text: {
                      color: 'secondary.200',
                    },
                  }}
                  _text={{
                    color: 'secondary.100',
                    textDecoration: 'none',
                    textTransform: 'none',
                  }}
                  fontWeight={'bold'}
                  onPress={() => navigate('/')}
                >
                  Create an account
                </Link>
              </Text>
            </VStack>

            <VStack alignItems="center" pt={16}>
              <Text
                color={'secondary.500'}
                fontWeight={'normal'}
                maxW={'270px'}
                textAlign={'center'}
              >
                Got questions? Reach out at{' '}
                <Link
                  _hover={{
                    _text: {
                      color: 'secondary.200',
                    },
                  }}
                  _text={{
                    color: 'secondary.100',
                    fontWeight: 'normal',
                    textDecoration: 'none',
                    textTransform: 'none',
                  }}
                  href={'mailto:support@ayblehealth.com'}
                >
                  support@ayblehealth.com
                </Link>{' '}
                or call us at{' '}
                <Link
                  _hover={{
                    _text: {
                      color: 'secondary.200',
                    },
                  }}
                  _text={{
                    color: 'secondary.100',
                    fontWeight: 'normal',
                    textDecoration: 'none',
                    textTransform: 'none',
                  }}
                  href="tel:8574169299"
                >
                  (857) 416-9299
                </Link>
              </Text>
            </VStack>
          </Box>

          <Image
            alt={'two people high-fiving'}
            resizeMode="contain"
            w={variant === 'small' ? '300px' : '50%'}
            size={
              variant === 'small'
                ? '300px'
                : ['xs', 'sm', 'md', 'lg', 'xl', 'xl']
            }
            maxW={'500px'}
            source={{
              uri: highFiveImg,
            }}
          />

          {variant === 'small' && (
            <Flex
              flexDir={'column'}
              // @ts-ignore
              gap={'24px'}
              alignItems={'center'}
              overflow={'scroll'}
              pb={3}
              px={2}
              mt={-32}
            >
              {REVIEWS.map(({ content, name, stars }) => (
                <ReviewCard content={content} name={name} numStars={stars} />
              ))}
            </Flex>
          )}
        </VStack>
      </GradientBox>
    </VStack>
  );
};
