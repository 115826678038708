import { CardScanApi } from '@cardscan.ai/insurance-cardscan-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCardScanFetchCard } from './useCardScanFetchCard';
import { camelCase } from 'lodash';

const convertAttributesToCamelCase = (obj: object) => {
  return Object.keys(obj).reduce((acc, k: string) => {
    const key = camelCase(k);
    return { ...acc, [key]: obj[k] };
  }, {});
};

type Props = {
  cardScanToken: string | undefined;
  onScanSuccess: (card: any) => void;
};

export const useCardScanUpload = ({ cardScanToken, onScanSuccess }: Props) => {
  const [uploadedCardId, setUploadedCardId] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);
  const cardScanApiClient = useRef<CardScanApi>();

  // scan the card data as soon as we have uploaded it
  const { card, isFetching } = useCardScanFetchCard({
    cardId: uploadedCardId,
    cardScanApiClient: cardScanApiClient?.current,
  });

  const isCardError = useMemo(() => !!card && card.state === 'error', [card]);

  const isScanningContainingUsefulData = useMemo(() => {
    // when no card was scanned successfully we cannot check what actually are the data within the card
    if (!card || card.state !== 'completed') return true;

    return (
      !!card?.details?.member_number &&
      !!card?.details?.group_number &&
      !!card?.details?.member_name
    );
  }, [card]);

  const isFetchingCard = useMemo(
    () => isFetching || (!!card && !isCardError && card.state !== 'completed'),
    [card, isCardError, isFetching]
  );

  // initialize API client for the upload as soon as we get the CardScan token
  useEffect(() => {
    if (!cardScanToken) return;

    cardScanApiClient.current = new CardScanApi({
      sessionToken: cardScanToken,
      live: false,
    });
  }, [cardScanToken]);

  // as soon as we have the data we trigger the callback
  useEffect(() => {
    if (
      isCardError ||
      !isScanningContainingUsefulData ||
      !uploadedCardId ||
      isFetchingCard
    )
      return;

    const details = convertAttributesToCamelCase(card.details);
    const camelCasedCard = {
      ...card,
      details,
    };

    onScanSuccess(camelCasedCard);
    setUploadedCardId('');
  }, [
    card,
    isCardError,
    isFetchingCard,
    isScanningContainingUsefulData,
    onScanSuccess,
    uploadedCardId,
  ]);

  // function exposed to the outside to let the users uploading their card
  const uploadCard = useCallback(async (file: File) => {
    if (!cardScanApiClient.current) return;

    setIsUploading(true);

    try {
      const cardId = await cardScanApiClient.current.uploadCardImage(file);
      setUploadedCardId(cardId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }, []);

  return {
    isCardError: isCardError || !isScanningContainingUsefulData,
    isFetchingCard,
    isUploadingCard: isUploading,
    uploadCard,
  };
};
