import { useEffect, useState } from 'react';
import { Text, useMediaQuery } from 'native-base';

export const CountDownTimer = ({ seconds }: { seconds: number }) => {
  const [isMediumScreen] = useMediaQuery({ minHeight: 830, maxHeight: 909 });
  const [isLargeScreen] = useMediaQuery({ minHeight: 910 });
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  return (
    <Text
      color={'white'}
      fontWeight={'bold'}
      fontSize={isMediumScreen ? 'xl' : isLargeScreen ? '2xl' : 'lg'}
    >
      {`${Math.floor(time / 60)}`.padStart(2, '0')}:
      {`${time % 60}`.padStart(2, '0')}
    </Text>
  );
};
