import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ITheme } from 'native-base';

const Container = styled('div')<{
  height: number;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: ${(p) => `${p.height}px`};

  &:hover {
    cursor: pointer;
  }
`;

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const Label = styled('label')<{ theme?: ITheme; error: boolean }>`
  align-items: center;
  background: linear-gradient(to bottom, #c7d2fe, #c9eae8);
  border: ${(p) =>
    p.error ? `2px solid ${p.theme.colors.error['500']}` : null};
  border-radius: 4px;
  color: #505455;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  transition: background 1s ease-out;
  width: 100%;
`;

type Props = {
  acceptedFileTypes?: string;
  error?: boolean;
  height?: number;
  onSelectedFileChange: (file: File) => void;
  placeholder?: string;
  showFileName?: boolean;
};

export const InputFile = ({
  acceptedFileTypes = 'image/png, image/jpeg',
  error = false,
  height = 44,
  onSelectedFileChange,
  placeholder = 'Select File',
  showFileName = false,
}: Props) => {
  const [fileName, setFileName] = useState('');

  return (
    <Container height={height}>
      <FileInput
        accept={acceptedFileTypes}
        id="file"
        onChange={(e: any) => {
          // Get the selected file
          const [file] = e.target.files as File[];
          // Get the file name and size
          const { name: fileName, size } = file;
          // Convert size in bytes to kilo bytes
          const fileSize = (size / 1000).toFixed(2);
          // Set the text content
          const fileNameAndSize = `${fileName} - ${fileSize}KB`;

          setFileName(fileNameAndSize);

          onSelectedFileChange(file);
        }}
        type={'file'}
      />
      <Label error={error} htmlFor="file">
        {(showFileName && fileName) || placeholder}
      </Label>
    </Container>
  );
};
