import { Button, Image, ITheme, Text, VStack } from 'native-base';
import HeaderNav from '../HeaderNav/HeaderNav';
import { ScrollingText } from '../LandingPage/ScrollingText';
import { PlanDisplay } from './PlanDisplay';
import { MobileReviewsCarousel } from './MobileReviewsCarousel';
import styled from '@emotion/styled';
import { StripePrice } from '../../types';

const foodTrackImg = '../../food-track.png';

const GradientBox = styled('div')<{ theme?: ITheme }>`
  background-image: ${(p) =>
    `linear-gradient(to bottom, ${p.theme.colors.muted['100']}, 90%, ${p.theme.colors.blue['100']}`});
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ImgContainer = styled('div')`
  & > div > div {
    background-position-y: 100px;
  }
`;

type Props = {
  discount: number;
  discountCode: string;
  onSelectPlan: () => void;
  plan: StripePrice;
};

export const MobilePlansPage = ({
  discount,
  discountCode,
  onSelectPlan,
  plan,
}: Props) => {
  return (
    <GradientBox>
      <HeaderNav hideBack page={1} />

      <VStack mb={10}>
        <ScrollingText />

        <PlanDisplay
          discount={discount}
          discountCode={discountCode}
          onSelectPlan={onSelectPlan}
          plan={plan}
        />
      </VStack>

      <VStack>
        <MobileReviewsCarousel />
      </VStack>

      <VStack alignItems={'center'} px={8} py={16}>
        <Button bgColor={'secondary.500'} onPress={onSelectPlan}>
          <Text color={'white'} fontWeight={'medium'} px={6}>
            Claim My Membership Offer Now
          </Text>
        </Button>

        <Text
          color={'secondary.500'}
          fontWeight={'regular'}
          fontSize={'lg'}
          mt={6}
          textAlign={{ base: 'center', md: 'left' }}
        >
          Get {discount}% off forever with code{' '}
          <Text fontWeight={'bold'}>{discountCode}</Text>
        </Text>
      </VStack>

      <VStack alignItems={'center'} justifyContent={'center'}>
        <ImgContainer>
          <Image
            alt="food track image"
            resizeMode={'contain'}
            size={400}
            source={{ uri: foodTrackImg }}
          />
        </ImgContainer>
      </VStack>
    </GradientBox>
  );
};
