import { Box, useBreakpointValue, VStack } from 'native-base';
import { useMatch } from 'react-router-dom';
import { Body } from './Body';
import { Header } from './Header';
import { FORM_ACTIONS } from '../../utils/formActions';
import { useEffect } from 'react';
import { useAffiliate } from '../../api/affiliates/useAffiliate';
import { useFormData } from '../../utils/formReducer';
import { MobileHeader } from './MobileHeader';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams';

const Overlay = styled('div')`
  background-color: white;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

type Props = {
  onStartOnboarding: () => void;
};

export const LandingPage = ({ onStartOnboarding }: Props) => {
  const navigate = useNavigateWithParams();
  const { dispatch } = useFormData();
  const { affiliate } = useAffiliate();
  const isShowingLogin = !!useMatch('login');

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  useEffect(() => {
    if (!!affiliate) {
      dispatch({
        type: FORM_ACTIONS.INIT_AFFILIATE,
        payload: affiliate,
      });
    }
  }, [affiliate, dispatch]);

  if (isMobile) {
    return isShowingLogin ? (
      <Outlet />
    ) : (
      <Box height={'100vh'}>
        <VStack testID={'mobile-landing-page'}>
          <MobileHeader onGetStartedButtonClick={onStartOnboarding} />

          <Body
            onClickLogin={() => navigate('/login')}
            onGetStartedButtonClick={onStartOnboarding}
          />
        </VStack>
      </Box>
    );
  }

  return (
    <>
      <Outlet />

      {isShowingLogin && <Overlay />}

      <VStack testID={'landing-page'}>
        <Header
          onClickLogin={() => navigate('/login')}
          onGetStartedButtonClick={onStartOnboarding}
        />

        <Body onGetStartedButtonClick={onStartOnboarding} />
      </VStack>
    </>
  );
};
