import { Flex, Heading, View } from 'native-base';
import { ReviewCard } from '../ui/ReviewCard';
import { REVIEWS } from './constants';

export const MobileReviewsCarousel = () => {
  return (
    <>
      <Heading
        color={'secondary.500'}
        textAlign={'left'}
        fontWeight={'normal'}
        mb={4}
        px={8}
      >
        You’re in good company
      </Heading>

      <View>
        <Flex
          flexDir={'column'}
          // @ts-ignore
          gap={'24px'}
          alignItems={'center'}
          overflow={'scroll'}
          pb={3}
          px={2}
        >
          {REVIEWS.map(({ content, name, stars }) => (
            <ReviewCard content={content} name={name} numStars={stars} />
          ))}
        </Flex>
      </View>
    </>
  );
};
