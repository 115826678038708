import { useBreakpointValue } from 'native-base';
import { LoginDrawer } from './LoginDrawer';
import { LoginPanel } from './LoginPanel';

export const LoginRoute = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  if (isMobile) return <LoginPanel variant={'small'} />;

  return <LoginDrawer />;
};
