import { HStack, Image, ITheme, VStack } from 'native-base';
import styled from '@emotion/styled';
import HeaderNav from '../HeaderNav/HeaderNav';
import { PlanDisplay } from './PlanDisplay';
import { ReviewsCarousel } from './ReviewsCarousel';
import { StripePrice } from '../../types';

const foodTrackImg = '../../food-track.png';

const GradientBox = styled('div')<{ theme?: ITheme }>`
  background-image: ${(p) =>
    `linear-gradient(to bottom, ${p.theme.colors.muted['100']}, 90%, ${p.theme.colors.blue['100']}`});
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ImgContainer = styled('div')`
  @media screen and (min-height: 730px) {
    & > div {
      height: 290px;
      width: 350px;
    }

    & > div > div {
      background-position-y: 40px !important;
    }
  }

  @media screen and (min-height: 830px) {
    & > div {
      height: 360px;
      width: 440px;
    }

    & > div > div {
      background-position-y: 50px !important;
    }
  }

  @media screen and (min-height: 910px) {
    & > div {
      height: 425px;
      width: 500px;
    }

    & > div > div {
      background-position-y: 60px !important;
    }
  }

  & > div > div {
    background-position-y: 30px;
  }
`;

type Props = {
  discount: number;
  discountCode: string;
  onSelectPlan: () => void;
  plan: StripePrice;
};

export const DesktopPlansPage = ({
  discount,
  discountCode,
  onSelectPlan,
  plan,
}: Props) => {
  return (
    <GradientBox>
      <HeaderNav hideBack page={1} />

      <HStack flex={1} w={'100%'}>
        <VStack flex={1} px={16} alignItems={'flex-end'}>
          <PlanDisplay
            discount={discount}
            discountCode={discountCode}
            onSelectPlan={onSelectPlan}
            plan={plan}
          />
        </VStack>

        <VStack flex={1}>
          <VStack flex={1} justifyContent={'center'} pt={8}>
            <ReviewsCarousel />
          </VStack>

          <VStack alignItems={'center'} justifyContent={'center'}>
            <ImgContainer>
              <Image
                alt="food track image"
                resizeMode={'contain'}
                minH={'200px'}
                minW={'230px'}
                source={{ uri: foodTrackImg }}
              />
            </ImgContainer>
          </VStack>
        </VStack>
      </HStack>
    </GradientBox>
  );
};
