import { CardScanApi } from '@cardscan.ai/insurance-cardscan-react';
import { useQuery } from '@tanstack/react-query';

const computeRefetchInterval = (data: any) => {
  if (data?.state === 'error') return false;
  if (!!data && data?.state !== 'completed') return 2000;
  return false;
};

type Props = {
  cardId: string;
  cardScanApiClient: CardScanApi | undefined;
};

export const useCardScanFetchCard = ({ cardId, cardScanApiClient }: Props) => {
  const fetchCard = async () => {
    const res = await cardScanApiClient?.getCard(cardId);
    return res?.data;
  };

  const { data, isFetching, isError } = useQuery<any, Error>(
    ['cardscan-fetch-card', cardId],
    () => fetchCard(),
    {
      refetchInterval: computeRefetchInterval,
      enabled: !!cardId && !!cardScanApiClient,
      refetchOnWindowFocus: false,
    }
  );

  return {
    card: data,
    isError,
    isFetching,
  };
};
