import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { animated, useSpring } from 'react-spring';
import styled from '@emotion/styled';
import {
  CloseIcon,
  Flex,
  HStack,
  Heading,
  Pressable,
  useTheme,
  VStack,
  Divider,
  Image,
  ITheme,
} from 'native-base';

const backBttn = './../chevron-left.png';
const RESIZE_HANDLE_CLASS = 'drawer-resize-handle';
const DRAWER_MIN_WIDTH_PX = 496;
const DEFAULT_LOCAL_STORAGE_DRAWER_WIDTH_KEY = 'drawer-width';

const iconStyle = {
  width: 32,
  height: 32,
};

const DrawerWrapper = styled(animated.div)`
  max-width: 100%;
  z-index: 1;
`;

const ResizeHandle = styled('div')<{ isResizing: boolean; theme?: ITheme }>`
  background-color: ${(p) =>
    p.isResizing ? p.theme.colors.primary['600'] : p.theme.colors.muted['400']};
  border: none;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  width: 2px;
  z-index: 99;
`;

type Props = {
  bgColor?: string;
  children: React.ReactNode;
  header?: string;
  isResizable?: boolean;
  onClose?: () => void;
  showHeader?: boolean;
};

export const Drawer = ({
  bgColor,
  children,
  onClose = () => {},
  header = '',
  isResizable = false,
  showHeader = true,
}: Props) => {
  const {
    colors: { muted },
  } = useTheme();
  const drawerRef = useRef<HTMLDivElement>();

  const [isVisible, setIsVisible] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [drawerWidthDelta, setDrawerWidthDelta] = useState(0);

  const initialWidth = isResizable
    ? localStorage.getItem(DEFAULT_LOCAL_STORAGE_DRAWER_WIDTH_KEY)
      ? `${localStorage.getItem(DEFAULT_LOCAL_STORAGE_DRAWER_WIDTH_KEY)}px`
      : '30%'
    : '50%';

  const style = useSpring({
    backgroundColor: bgColor || muted['100'],
    'box-shadow': `0px 0px 36px 1px ${muted['500']}`,
    height: '100vh',
    minWidth: `${DRAWER_MIN_WIDTH_PX}px`,
    overflow: 'auto',
    position: 'fixed',
    right: isVisible ? 0 : -600,
    top: 0,
    width: isVisible ? null : initialWidth,
    zIndex: 2,
  });

  const readCurrentWidth = () => {
    if (!document.defaultView) return 0;
    if (!drawerRef?.current) return 0;

    return parseInt(
      document.defaultView.getComputedStyle(drawerRef.current).width,
      10
    );
  };

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    const currentWidth = Math.max(DRAWER_MIN_WIDTH_PX, readCurrentWidth());
    localStorage.setItem(
      DEFAULT_LOCAL_STORAGE_DRAWER_WIDTH_KEY,
      `${currentWidth}`
    );
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (!isResizing) return;
      if (!drawerRef?.current) return;

      setDrawerWidthDelta(
        mouseMoveEvent.clientX - drawerRef.current.getBoundingClientRect().left
      );
    },
    [isResizing]
  );

  useEffect(() => {
    if (!isResizable) return;

    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizable, resize, stopResizing]);

  useEffect(() => {
    if (!drawerRef?.current) return;

    const currentWidth = readCurrentWidth();
    drawerRef.current.style.width = +currentWidth - drawerWidthDelta + 'px';
  }, [drawerWidthDelta]);

  useLayoutEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <DrawerWrapper
      // @ts-ignore
      style={style}
      // @ts-ignore
      ref={drawerRef}
      onMouseDown={(e) => {
        // @ts-ignore
        if (e.target.classList.contains(RESIZE_HANDLE_CLASS))
          e.preventDefault();
      }}
    >
      {isResizable && (
        <ResizeHandle
          className={RESIZE_HANDLE_CLASS}
          isResizing={isResizing}
          onMouseDown={() => setIsResizing(true)}
        />
      )}

      <VStack>
        {showHeader && (
          <VStack
            backgroundColor={muted['100']}
            position="sticky"
            top={0}
            zIndex={11}
          >
            <Flex
              alignItems="start"
              flexDirection="row"
              justifyContent="space-between"
              p={6}
            >
              <HStack alignItems="center">
                <Pressable variant={'closer'} onPress={onClose} mr={2}>
                  <Image alt={'back button'} src={backBttn} style={iconStyle} />
                </Pressable>
                <Heading size="md">{header}</Heading>
              </HStack>
              <Pressable variant={'closer'} onPress={onClose}>
                <CloseIcon />
              </Pressable>
            </Flex>
            <Divider />
          </VStack>
        )}

        <VStack overflow="auto" p={showHeader ? 6 : null}>
          {children}
        </VStack>
      </VStack>
    </DrawerWrapper>
  );
};
