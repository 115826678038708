import { useState, useEffect } from 'react';
import { Flex, Spinner } from 'native-base';
import {
  CardScanView,
  CardScanModel,
} from '@cardscan.ai/insurance-cardscan-react';
import CardInputButton from './CardInputButton';
import { useCardScanAuth } from '../../../api/cardscan/useCardScanAuth';
import { useSegment } from '../../../hooks/useSegment';
import {
  TRACKING_CARD_SCAN_START,
  TRACKING_CARD_SCAN_SUCCESS,
  TRACKING_CARD_SCAN_FAILED,
} from '../../OnboardingForm/constants';
import { InputFile } from '../../Form/InputFile';
import { useCardScanUpload } from '../../../api/cardscan/useCardScanUpload';

const scannerImage = '../../card-scanner.png';
const uploaderImage = '../../card-uploader.png';

export const CardInput = ({ onScanSuccess, userId }) => {
  const [inputView, setInputView] = useState<'scan' | 'upload' | null>(null);
  const { authObject, isLoading } = useCardScanAuth({ userId });
  const { track } = useSegment();
  const { isCardError, isFetchingCard, isUploadingCard, uploadCard } =
    useCardScanUpload({
      cardScanToken: authObject?.Token,
      onScanSuccess: (card) => {
        setInputView(null);
        onScanSuccess(card);
      },
    });

  const { REACT_APP_ENV_LABEL = 'unknown' } = process.env;
  const islive = REACT_APP_ENV_LABEL === 'production';

  useEffect(() => {
    CardScanModel.warm();
  }, []);

  if (isLoading) return <Spinner />;

  const isScannerSelected = inputView === 'scan';
  const isUploaderSelected = inputView === 'upload';

  return (
    <>
      <Flex
        flex={1}
        flexDir={'row'}
        // @ts-ignore
        gap={'16px'}
        justifyContent={'center'}
        my={4}
      >
        {!isUploaderSelected && (
          <CardInputButton
            text={'Scan insurance card with camera'}
            image={scannerImage}
            isActive={isScannerSelected}
            onPress={() => setInputView((v) => (v === 'scan' ? null : 'scan'))}
          />
        )}

        {!isScannerSelected && (
          <CardInputButton
            isActive={isUploaderSelected}
            image={uploaderImage}
            text={'Upload images of your insurance card'}
            onPress={() =>
              setInputView((v) => (v === 'upload' ? null : 'upload'))
            }
          />
        )}
      </Flex>

      {inputView === 'scan' && (
        <CardScanView
          live={islive}
          backsideSupport
          fullScreen={false}
          sessionToken={authObject?.Token}
          cameraPermissionModalConfig={{ enabled: false }}
          onCancel={() => setInputView(null)}
          onScanStart={() => {
            track(TRACKING_CARD_SCAN_START, { userId });
          }}
          onSuccess={(data) => {
            track(TRACKING_CARD_SCAN_SUCCESS, { userId });
            onScanSuccess(data);
            setTimeout(() => {
              setInputView(null);
            }, 500);
          }}
          onError={(error) => {
            track(TRACKING_CARD_SCAN_FAILED, { userId, error });
            console.error(error);
          }}
        />
      )}

      {inputView === 'upload' && (
        <InputFile
          error={isCardError}
          onSelectedFileChange={uploadCard}
          placeholder={
            isCardError
              ? 'Error while scanning your document, please try again'
              : isUploadingCard
              ? 'Uploading card, please wait...'
              : isFetchingCard
              ? 'Scanning card info, please wait...'
              : 'Upload image'
          }
        />
      )}
    </>
  );
};

export default CardInput;
