import {
  Box,
  Flex,
  Heading,
  Image,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { ImageBackground } from 'react-native';

const decorativeBackground = './../decorative_bg.png';
const uhc = './../provider_logos/uhc.png';
const aetna = './../provider_logos/aetna-logo.svg';
const bcbs = './../provider_logos/bcbs.svg';
const cigna = './../provider_logos/cigna.svg';
const marpai = './../provider_logos/marpai.png';

const logos = [
  { source: uhc, alt: 'UHC' },
  { source: aetna, alt: 'Aetna' },
  {
    source: bcbs,

    alt: 'Blue Cross Blue Shield',
  },
  { source: cigna, alt: 'Cigna' },
  { source: marpai, alt: 'Marpai' },
];

const styles = {
  logo: {
    backgroundSize: 'contain',
    width: '170px',
    height: '65px',
  },
};

export const LogosPageScaffolding = ({ children }) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <Flex
      flex={1}
      flexDirection={isMobile ? 'column' : 'row'}
      h={'100%'}
      testID={'logos-page-scaffolding'}
    >
      <VStack flex={1} pr={4}>
        {children}
      </VStack>

      <VStack
        flex={1}
        backgroundColor={'secondary.500'}
        justifyContent={'space-between'}
        alignSelf={isMobile ? 'default' : 'stretch'}
        mt={isMobile ? 'auto' : undefined}
        mx={-4}
      >
        <Box
          backgroundColor={'white'}
          rounded={10}
          mt={10}
          p={'65px'}
          maxW={'90%'}
          alignSelf={'center'}
        >
          <Heading
            alignSelf={'center'}
            fontSize={'lg'}
            textTransform={'uppercase'}
            fontFamily={'body'}
            lineHeight={'26px'}
          >
            in-network with leading health {'\n'} insurance providers,
            including:
          </Heading>
          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            alignContent={'center'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            pt={6}
            //@ts-ignore
            gap={6}
          >
            {logos.map((item) => {
              return (
                <Box key={item.source}>
                  <Image
                    mt={5}
                    key={item.source}
                    style={styles.logo}
                    resizeMode={'contain'}
                    alt={item.alt}
                    source={{
                      uri: item.source,
                    }}
                  />
                </Box>
              );
            })}
          </Flex>
        </Box>

        {/* @ts-ignore */}
        <ImageBackground
          style={{ height: '300px', justifyContent: 'flex-end' }}
          source={{ uri: decorativeBackground }}
          resizeMode="cover"
        />
      </VStack>
    </Flex>
  );
};
