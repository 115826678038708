import { Text } from 'native-base';

export const REVIEWS = [
  {
    content: (
      <Text fontWeight={'normal'} fontSize={'md'}>
        “<b>My Ayble coach literally changed my life.</b> We figured out my
        trigger foods (the food logging was also the easiest I’ve ever tried)
        and my bloating has gone way down pretty quickly. They also have a ton
        of mind-gut meditations that are great — it’s like Calm but specifically
        for GI.”
      </Text>
    ),
    name: 'Julia R., Bloating',
    stars: 5,
  },
  {
    content: (
      <Text fontWeight={'normal'} fontSize={'md'}>
        “After years of frustrating gut symptoms and so much trial and error
        with no good answers, it’s not an overstatement to say Ayble changed my
        life.{' '}
        <b>It’s the best GI care I’ve ever received. 10/10 would recommend.</b>”
      </Text>
    ),
    name: 'Chris M., IBD',
    stars: 5,
  },
  {
    content: (
      <Text fontWeight={'normal'} fontSize={'md'}>
        “My GI doc from the Mayo Clinic recommended I try Ayble to help me learn
        how to manage my IBS with diet and mind-gut meditations. It's helped me
        <b>
          build a toolkit of tactics that's given me a greater sense of control
          over my body
        </b>{' '}
        for the first time in a long time.”
      </Text>
    ),
    name: 'Jackie D., IBS',
    stars: 5,
  },
];
