import { Flex, Heading, View } from 'native-base';
import { ReviewCard } from '../ui/ReviewCard';
import { REVIEWS } from './constants';

export const ReviewsCarousel = () => {
  return (
    <>
      <Heading
        color={'secondary.500'}
        textAlign={'left'}
        fontWeight={'normal'}
        mb={4}
      >
        You’re in good company
      </Heading>

      <View>
        {/* @ts-ignore */}
        <Flex flexDir={'row'} overflow={'scroll'} gap={5} pb={3} px={2}>
          {REVIEWS.map(({ content, name, stars }, i) => (
            <ReviewCard
              key={i}
              content={content}
              name={name}
              numStars={stars}
            />
          ))}
        </Flex>
      </View>
    </>
  );
};
