import styled from '@emotion/styled';
import {
  Box,
  CheckCircleIcon,
  Divider,
  Flex,
  Heading,
  HStack,
  ITheme,
  Text,
  useMediaQuery,
  VStack,
} from 'native-base';
import { CountDownTimer } from './CountDownTimer';
import { StripePrice } from '../../types';

const GradientBox = styled('div')<{ theme?: ITheme }>`
  background-image: ${(p) =>
    `linear-gradient(to bottom, ${p.theme.colors.secondary['500']}, ${p.theme.colors.secondary['200']}`});
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  display: flex;
  flex-direction: column;
  height: 90%;
  max-width: 900px;
  width: 100%;

  @media screen and (max-height: 1300px) {  
    & {
        max-width: 800px;
    }
  }
    
  @media screen and (max-height: 850px) {
    & {
        max-width: 600px;
    }
  }
`;

const StyledButton = styled('button')<{ maxW: string; theme?: ITheme }>`
  background-image: linear-gradient(to right, #dcedff, #6acbc4);
  border: none;
  border-radius: 32px;
  cursor: pointer;
  height: 40px;
  max-width: ${(p) => p.maxW};
  width: 100%;
`;

const ITEMS = [
  'Unlimited GI health coach visits',
  '24/7 messaging',
  'Track symptom improvement in the app',
  'Food trigger identification ',
  'Stress tools to reduce flare ups',
];

const formatPriceStr = (priceStr: string, symbol = '$') => {
  return `${symbol}${priceStr.slice(0, -2)}.${priceStr.slice(-2)}`;
};

type Props = {
  discount: number;
  discountCode: string;
  onSelectPlan: () => void;
  plan: StripePrice;
};

export const PlanDisplay = ({
  discount,
  discountCode,
  onSelectPlan,
  plan,
}: Props) => {
  const [isMediumScreen] = useMediaQuery({ minHeight: 830, maxHeight: 909 });
  const [isLargeScreen] = useMediaQuery({ minHeight: 910 });
  const { unit_amount, recurring = {} } = plan as any;

  return (
    <GradientBox>
      <VStack flex={1} p={12}>
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <Box backgroundColor={'secondary.200'} rounded={'full'}>
            <Text
              px={4}
              py={1}
              color={'white'}
              fontWeight={'regular'}
              fontSize={isMediumScreen ? 'lg' : isLargeScreen ? 'xl' : 'md'}
            >
              Monthly Membership
            </Text>
          </Box>
        </HStack>

        <HStack alignItems={'flex-end'} pt={3}>
          <Heading
            color={'white'}
            fontSize={isMediumScreen ? '40px' : isLargeScreen ? '50px' : '3xl'}
            fontWeight={'normal'}
          >
            {`${formatPriceStr(
              (unit_amount / (recurring?.interval_count || 1)).toString()
            )}`}
          </Heading>{' '}
          <Text
            color={'primary.50'}
            mb={1}
            fontSize={isMediumScreen ? 'md' : isLargeScreen ? 'lg' : 'md'}
          >
            {' '}
            / {recurring?.interval || 'month'} total
          </Text>
        </HStack>

        <HStack mt={2}>
          <Text
            color={'#5299A9'}
            fontSize={isMediumScreen ? 'regular' : isLargeScreen ? 'lg' : 'xs'}
          >
            Cancel anytime, no commitment
          </Text>
        </HStack>

        <Divider bg={'#5299A9'} mt={3} />

        <VStack pt={5}>
          <Text
            color={'white'}
            fontWeight={'bold'}
            fontSize={isMediumScreen ? 'lg' : isLargeScreen ? 'xl' : 'md'}
          >
            Long-term GI support, tailored to you:
          </Text>

          <VStack pl={3} pt={3}>
            {ITEMS.map((text, i) => (
              <HStack key={i} alignItems={'center'} mb={2}>
                <CheckCircleIcon color={'white'} size={3} />
                <Text
                  color={'white'}
                  pl={2}
                  fontSize={isMediumScreen ? 'md' : isLargeScreen ? 'lg' : 'xs'}
                >
                  {text}
                </Text>
              </HStack>
            ))}
          </VStack>
        </VStack>

        <VStack
          alignItems={{ base: 'center', md: 'flex-start' }}
          flex={3}
          justifyContent={'center'}
          paddingY={{ base: 8, md: undefined }}
        >
          <StyledButton
            maxW={isMediumScreen ? '350px' : isLargeScreen ? '400px' : '300px'}
            onClick={onSelectPlan}
          >
            <Text
              color={'secondary.500'}
              minW={'200px'}
              fontWeight={'medium'}
              fontSize={{
                base: 'sm',
                md: 'md',
                lg: isMediumScreen ? 'lg' : isLargeScreen ? 'md' : 'sm',
              }}
            >
              Get Your Personalized Care Plan Now
            </Text>
          </StyledButton>
        </VStack>

        <Flex
          alignItems={{ base: 'center', md: 'flex-end' }}
          flex={1}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <VStack flex={1} justifyContent={'flex-end'}>
            <Text
              color={'tertiary.500'}
              fontWeight={'regular'}
              fontSize={isMediumScreen ? 'md' : isLargeScreen ? 'lg' : 'sm'}
              textAlign={{ base: 'center', md: 'left' }}
            >
              Get {discount}% off forever with code{' '}
              <Text fontWeight={'bold'}>{discountCode}</Text>
            </Text>

            <Text
              color={'white'}
              fontWeight={'normal'}
              pt={1}
              fontSize={isMediumScreen ? 'xs' : isLargeScreen ? 'sm' : '2xs'}
              textAlign={{ base: 'center', md: 'left' }}
            >
              Valid for the next 10 minutes only!
            </Text>
          </VStack>

          <VStack minW={'56px'} paddingTop={{ base: 3, md: undefined }}>
            <CountDownTimer seconds={600} />
          </VStack>
        </Flex>
      </VStack>
    </GradientBox>
  );
};
