import { useAuth } from '../../hooks';
import { useLocation } from 'react-router-dom';
import { useUserCoachProviderPublicInfo } from '../../hooks/useUserCoachProviderPublicInfo';

export const useThankYouPage = () => {
  const { isAuthenticated, user } = useAuth();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userIdQueryParam = searchParams.get('userId') ?? undefined;
  const decodedUserIdQueryParam =
    userIdQueryParam && decodeURIComponent(userIdQueryParam);

  // When users are authenticated, they already completed their onboarding flow/payment earlier
  // so, we use their id right away to get coach info.
  // When users are not, we read their id from the url as they either come from the Stripe
  // page after the payment or from the eligibility page after the successful check.
  // If no user id is present, they are not authenticated so they will be redirected to home.
  const userId = isAuthenticated ? user?.id : decodedUserIdQueryParam;
  const { coachInfo, providerInfo } = useUserCoachProviderPublicInfo(userId);

  if (!userId) return { redirectToHome: true };

  return { coachInfo, providerInfo, redirectToHome: false };
};
