import { Spinner, useBreakpointValue } from 'native-base';
import { usePlansPage } from './hooks/usePlansPage';
import { DesktopPlansPage } from './DesktopPlansPage';
import { MobilePlansPage } from './MobilePlansPage';
import useStripePlans from '../../api/onboarding/useStripePlans';

export const PlansPage = () => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  const { discount, discount_code, handlePlanSelect, isLoadingAffiliate } =
    usePlansPage();
  const { planConfig, error, loading } = useStripePlans();
  const pricePlan = planConfig?.coach?.prices?.[0];
  const selectPlan = () => handlePlanSelect(pricePlan);

  if (loading || isLoadingAffiliate) {
    return <Spinner testID={'plans-page-loading-spinner'} />;
  }

  if (error || !pricePlan) return null;

  if (isMobile) {
    return (
      <MobilePlansPage
        discount={discount}
        discountCode={discount_code}
        onSelectPlan={selectPlan}
        plan={pricePlan}
      />
    );
  }

  return (
    <DesktopPlansPage
      discount={discount}
      discountCode={discount_code}
      onSelectPlan={selectPlan}
      plan={pricePlan}
    />
  );
};
