import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks';
import { Diagnosis, DiagnosisCategory } from '../types';

export const useDiagnosis = () => {
  const { api } = useApi();

  const fetchDiagnosis = async (): Promise<Diagnosis[]> => {
    const results = await api({
      route: '/diagnosis/',
    });

    return results.filter(
      (item: Diagnosis) =>
        item?.category === DiagnosisCategory.Other ||
        item?.category === DiagnosisCategory.UpperGI
    );
  };

  const { data, isLoading, isError } = useQuery<Diagnosis[], Error>(
    ['public-diagnosis'],
    fetchDiagnosis,
    { placeholderData: [], refetchOnWindowFocus: false }
  );

  return {
    isLoading,
    isError,
    data,
  };
};
