import {
  createSearchParams,
  NavigateFunction,
  NavigateOptions,
  To,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

// Hook that wraps the original useNavigate to retain the search params if any
export const useNavigateWithParams = (): NavigateFunction => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Overriding the former navigate to retain the search parameters
  const navigateWithTo = (to: To, options?: NavigateOptions) => {
    navigate(
      {
        ...(typeof to === 'object' ? { ...to } : { pathname: to }),
        search: createSearchParams(searchParams).toString(),
      },
      options
    );
  };

  // When using delta, it is the same as using the original one
  const navigateWithDelta = (delta: number) => {
    navigate(delta);
  };

  return (toOrDelta: To | number, options?: NavigateOptions) => {
    if (typeof toOrDelta === 'number') {
      navigateWithDelta(toOrDelta);
      return;
    }

    navigateWithTo(toOrDelta, options);
  };
};
