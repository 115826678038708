import { useTheme, VStack } from 'native-base';
import { Drawer } from '../Drawer';
import { LoginPanel } from './LoginPanel';

export const LoginDrawer = () => {
  const {
    colors: { blue },
  } = useTheme();

  return (
    <Drawer bgColor={blue['100']} showHeader={false}>
      <VStack h={'100vh'} overflowY={'hidden'}>
        <LoginPanel />
      </VStack>
    </Drawer>
  );
};
