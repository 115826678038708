import { Box, Flex, HStack, Image, useBreakpointValue } from 'native-base';
import { Banner } from './Banner';
import { CoBrandingLogo } from './CoBrandingLogo';
import { HeaderActions } from './HeaderActions';

const logo = '../../FA__ayble_health_Logo_Isabelline_white.png';

type Props = {
  onClickLogin: () => void;
  onGetStartedButtonClick: () => void;
};

export const Header = ({ onClickLogin, onGetStartedButtonClick }: Props) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  return (
    <Box>
      <Banner />

      <Flex
        alignItems={isMobile ? 'center' : undefined}
        bgColor={'secondary.500'}
        direction={isMobile ? 'column' : 'row'}
        height={isMobile ? undefined : 128}
        justifyContent={'space-between'}
        px={6}
      >
        <HStack>
          <Image
            alt="Ayble Health logo"
            resizeMode={'contain'}
            size={'xl'}
            source={{
              uri: logo,
            }}
            testID={'ayble-health-logo'}
          />

          <CoBrandingLogo />
        </HStack>

        <HeaderActions
          onGetStartedButtonClick={onGetStartedButtonClick}
          onClickLogin={onClickLogin}
        />
      </Flex>
    </Box>
  );
};
