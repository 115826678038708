import { useState } from 'react';
import { useApi } from './useApi';
import { ApiRequest } from '../types';

export const useLogin = () => {
  const { api } = useApi();
  const [loginMessage, setLoginMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const sendNewLoginLink = async (email) => {
    setIsLoading(true);

    try {
      // TODO: Setup better random nonce/state value to confirm auth flow
      const nonce = Math.floor(Math.random() * 1000 * (Math.random() * 1000));
      const url = `/login/${nonce}`;

      const request: ApiRequest<{ email: string }> = {
        route: url,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        payload: { email },
      };

      await api(request);

      setLoginMessage(`Login link sent to: ${email}`);

      setIsLoading(false);
    } catch (err: any) {
      setLoginMessage(err.message || 'Failed to send login link');
      console.error('Failed Register User', err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    loginMessage,
    sendNewLoginLink,
  };
};
